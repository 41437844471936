@import '../../styles/colors.scss';

.infoBlock {
  padding: 40px;
  text-align: center;

  .iconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    font-size: 45px;
    color: white;
    background-color: var(--color-primary);
    line-height: 1;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    text-align: center;
  }

  .title {
    color: var(--color-primary);

    margin: 0;
    padding: 15px 0;
    font-size: 24px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
  }
}

:root {
  --color-primary: rgb(255, 107, 83);
  --color-secondary: #001529;
  --color-gray-dark: gray;
  --color-gray-light: #ebebeb;

  --text-primary: #2d2d2d;

  --background-gray: #f8f8f8;
}

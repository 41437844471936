@import '../styles/colors.scss';

.rootSection {
  min-height: 100vh;
  flex-direction: row;
}

.sideBar {
  background-color: white;
  box-shadow: 5px 0 15px -5px rgba(115, 115, 115, 0.75);
  z-index: 1;

  &__row {
    margin-bottom: 40px;
    display: flex;
  }

  &__logo {
    display: flex;
    flex-direction: column;
    font-size: 1.8em;
    line-height: 1em;
    color: var(--color-primary);

    &__focus {
      font-weight: 700;
    }
  }

  .closeIcon {
    margin-left: auto;
  }
}

@import '../../styles/colors.scss';

.grayBackground {
  background-color: var(--background-gray);
}

.backdrop {
  position: fixed;
  background-image: url('https://despelmaeckers.be/images/header.jpg');
  background-size: cover;
  z-index: 5;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h1 {
    font-size: 10vw;
    font-weight: 900;
    color: white;
  }

  .scrollIcon {
    font-size: 8vw;
    color: white;
    margin-top: 25%;
  }
}

.content {
  position: absolute;
  z-index: 10;
  width: 100%;
  background-color: #fff;
  top: 100vh;
  overflow: hidden;
}

.consumptionItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.die.amount-1:after {
  box-shadow: 0 0.2em 0 #fff;
}
.die.amount-2:after {
  background: transparent;
  box-shadow: -2.3em -2.3em 0 #345, 2.3em 2.3em 0 #345, -2.3em -2.3em 0 #fff, 2.3em 2.4em 0 #fff;
}
.die.amount-3:after {
  box-shadow: -2.3em -2.3em 0 #345, 2.3em 2.3em 0 #345, -2.3em -2.3em 0 #fff, 2.3em 2.4em 0 #fff, 0 0.2em 0 #fff;
}
.die.amount-4:after {
  background: transparent;
  box-shadow: -2.3em -2.3em 0 #345, 2.3em 2.3em 0 #345, -2.3em 2.3em 0 #345, 2.3em -2.3em 0 #345, -2.3em -2.3em 0 #fff,
    2.3em 2.4em 0 #fff, -2.3em 2.4em 0 #fff, 2.3em -2.3em 0 #fff;
}
.die.amount-5:after {
  box-shadow: -2.3em -2.3em 0 #345, 2.3em 2.3em 0 #345, -2.3em 2.3em 0 #345, 2.3em -2.3em 0 #345, -2.3em -2.2em 0 #fff,
    2.3em -2.2em 0 #fff, 2.3em 2.4em 0 #fff, -2.3em 2.4em 0 #fff, 0 0.2em 0 #fff;
}
.die.amount-6:after {
  background: transparent;
  box-shadow: -2.3em -2.3em 0 #345, -2.3em 0 0 #345, -2.3em 2.3em 0 #345, 2.3em -2.3em 0 #345, 2.3em 0 0 #345,
    2.3em 2.3em 0 #345, -2.3em -2.1em 0 #fff, -2.3em 0.2em 0 #fff, -2.3em 2.4em 0 #fff, 2.3em 2.4em 0 #fff,
    2.3em 0.2em 0 #fff, 2.3em -2.1em 0 #fff;
}
.die {
  border-top: 1px solid #f1f1f1;
  width: 50px;
  height: 50px;
  border-radius: 10px;
  position: relative;
  margin: 10px;
  font-size: 6px;
  display: inline-block;
  box-shadow: 0px 5px 0 #ccc, 0 6px 3px #444, 0 10px 5px #999;
  background: -webkit-linear-gradient(to bottom, #fefefe, #f1f1f1 80%);
  background: linear-gradient(to bottom, #fefefe, #f1f1f1 80%);
}
.die:after {
  content: '';
  width: 20%;
  height: 20%;
  left: 50%;
  top: 50%;
  margin: -10%;
  background: #345;
  border-radius: 50%;
  display: block;
  position: absolute;
}

@import '../../styles/colors.scss';

.memberBlock {
  max-width: 300px;

  h1 {
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
  }

  img {
    max-width: 100%;
    border-radius: 25px;
  }

  .description {
    padding: 10px 0;
    border-top: 1px solid var(--color-gray-light);
    border-bottom: 1px solid var(--color-gray-light);
  }

  .fbLink {
    text-align: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-size: 2.5em;
    color: var(--color-gray-dark);

    &:hover {
      color: var(--color-primary);
    }
  }
}

@import '../../styles/colors.scss';

.event {
  text-align: center;

  h1 {
    margin-top: 25px;
    font-weight: 900;
  }

  a {
    cursor: pointer;

    h1:hover {
      color: var(--color-primary);
    }
  }

  h2 {
    font-size: 1em;
    color: var(--color-gray-dark);
  }
}

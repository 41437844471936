@import '../../styles/colors.scss';

.priceSection {
  text-align: center;

  .ant-row {
    align-items: end;
  }

  p {
    margin-bottom: 25px;
  }

  h1 {
    font-size: 50px;
    font-family: 'Roboto Condensed', sans-serif;
    color: var(--color-primary);
    font-weight: 900;
    margin-bottom: 10px;
  }

  img {
    max-width: 75%;
  }
}

@import '~antd/dist/antd.css';
@import './styles/container.scss';
@import './styles/colors.scss';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-print-color-adjust: exact !important;

  font-size: 18px;
  color: var(--text-primary);
}

* {
  scroll-behavior: smooth !important;
}

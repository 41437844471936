@import '../../../styles/colors.scss';

.pageLoader {
  background-color: var(--color-secondary);
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.numberBlock {
  h1 {
    font-size: 50px;
    border-bottom: 1px solid #ebebeb;
    font-family: 'Roboto Condensed', sans-serif;
    color: #ff6b53;
    font-weight: 900;
    margin-bottom: 10px;
  }

  p {
    color: #aaa;
    line-height: 1em;
  }
}

.numbersRow {
  padding: 0 25px;
  text-align: center;
}

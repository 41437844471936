@import '../../styles/colors.scss';

.sectionWrapper {
  padding: 60px 0;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
}

.sectionTitle {
  position: relative;
  margin-bottom: 60px;
  padding: 0;
  border-bottom: 0;
  text-align: center;
  font-size: 40px;
  text-transform: uppercase;
  font-weight: 700;
  color: var(--text-primary);

  &:after {
    position: absolute;
    left: calc(50% - 35px);
    width: 70px;
    content: '';
    border-bottom: 6px double var(--color-primary);
    bottom: -10px;
  }
}
